<template>
  <BaseBanner>
    <img
      src="@/assets/image/Video/Light.png"
      alt=""
      class="theCameraBanner__lightImage"/>
    <img
      src="@/assets/image/Video/Camera.png"
      alt=""
      class="theCameraBanner__cameraImage"/>

    <img
      src="@/assets/image/Video/Mountain.png"
      alt=""
      class="theCameraBanner__mountainImage"/>
    <slot></slot
  ></BaseBanner>
</template>

<script>
import BaseBanner from '@/components/BaseBanner.vue'

export default {
  components: {
    BaseBanner
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss">
.theCameraBanner {
  &__lightImage {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 24.35rem;
    height: 10.6rem;
    object-fit: contain;
  }

  &__cameraImage {
    position: absolute;
    left: 12.9rem;
    bottom: 7.65rem;
    width: 9.85rem;
    height: 5.95rem;
    object-fit: contain;
  }

  &__mountainImage {
    position: absolute;
    left: calc(50% + 1.8rem);
    bottom: 0;
    width: 20.3rem;
    height: 7.1rem;
    object-fit: contain;
  }
}

@media (max-width: 768px) {
  .theCameraBanner {
    &__cameraImage {
      width: 6.3rem;
      height: 3.9rem;
      left: 6.8rem;
    }

    &__lightImage {
      width: 15.6rem;
      height: 6.9rem;
    }

    &__mountainImage {
      width: 13rem;
      height: 4.6rem;
      left: auto;
      right: 0;
    }
  }
}
</style>
