<template>
  <main class="search">
    <TheCameraBanner>
      <div class="search__textContainer">
        <h2 class="search__title">
          全站搜尋
        </h2>
      </div>
    </TheCameraBanner>
    <section class="search__container container">
      <template v-if="searchLists.length !== 0">
        <SearchItem
          v-for="(item, index) in searchFilterLists"
          :key="index"
          :searchItem="item"
        />

        <div
          style="margin: 6rem auto 10rem auto; width: 100%; text-align: center;"
        >
          <div class="center">
            <paginate
              align="center"
              size="lg"
              :page-count="Math.ceil(searchLists.length/pageSize)"
              :click-handler="onCurrentPageChange"
              :prev-text="'上一頁'"
              :next-text="'下一頁'"
              :container-class="'pagination'"
              :page-class="'page-item'"
              :page-link-class="'page-link'"
              :prev-class="'page-item'"
              :next-class="'page-item'"
              :prev-link-class="'page-link'"
              :next-link-class="'page-link'"
              :active-class="'active'"
            />
          </div>
        </div>

        <hr class="search__hr" />
      </template>
      <p class="search__resultText" v-else>
        共有 {{ searchLists.length }} 項符合"{{ keyword }}"的查詢結果
      </p>
    </section>
  </main>
</template>

<script>
import TheCameraBanner from '@/components/TheCameraBanner.vue'
import SearchItem from '@/components/Search/SearchItem.vue'
import BasePagination from '@/components/BasePagination.vue'

// Api
import { apiSearch } from '@/api/webAPI'
import $ from "jquery";

export default {
  components: {
    TheCameraBanner,
    SearchItem,
    BasePagination
  },
  data() {
    return {
      pageSize:10,
      searchLists: [],
      currentPage: 1,
      keyword: ''
    }
  },
  computed: {
    searchFilterLists() {
      // page 1 => 0, 6
      // page 2 => 6, 12
      const pageSize = 10
      const startIndex = pageSize * (this.currentPage - 1)
      const endIndex = pageSize * this.currentPage
      return this.searchLists.slice(startIndex, endIndex)
    }
  },
  async created() {
    await this.fetchDasta()
  },
  watch: {
    $route(to, from) {
      this.fetchDasta()
    }
  },
  mounted() {
    $('ul.pagination >:first-child').attr('title', '上一頁')
    $('ul.pagination >:last-child').attr('title', '下一頁')
  },
  methods: {
    async fetchDasta() {
      const keyword = this.$route.query.keyword
      this.keyword = keyword
      if (!keyword) {
        this.$router.push({ name: 'Index' })
      }
      const keywordResponse = await apiSearch(keyword)

      this.searchLists = keywordResponse.data.data.map(item => {
        if (!item.category.includes('影音專區')) return item
        return {
          ...item,
          web: `${item.web}?title=${item.title}`
          // web: `${item.web.replace(
          //   'https://goldmuseum.omniguider.com/',
          //   '/'
          // )}?title=${item.title}`
        }
      })
      this.currentPage = 1
    },
    onCurrentPageChange(page) {
      this.currentPage = page
    }
  }
}
</script>

<style lang="scss">
.center{
  display: flex;
  align-items: center;
  flex-direction: column;
}

.page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.search {
  &__container {
    padding: 6.4rem 0;
  }

  &__resultText {
    font-size: 2.2rem;
    line-height: 3.2rem;
  }

  &__textContainer {
    width: 1280px;
    max-width: 95%;
    margin: 0 auto;
    padding-left: 25rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__title {
    flex-shrink: 0;
    width: 21.4rem;
    height: 5.35rem;
    text-indent: 101%;
    white-space: nowrap;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('~@/assets/image/Search/SearchTitle.png');
  }

  &__hr {
    border: none;
    border-bottom: 1px dashed #d0a805;
    width: 100%;
    margin: 1.2rem 0 10rem 0;
  }
}

@media (max-width: 768px) {
  .search {
    &__title {
      margin: 0 auto;
      margin-bottom: 2.05rem;
    }

    &__textContainer {
      width: 100%;
      flex-direction: column;
      align-content: center;
      padding: 0;
    }
  }
}
</style>
