<template>
  <a :href="searchItem.web">
    <div class="searchItem clickable">
      <div class="searchItem__content">
        <h3 class="searchItem__title">{{ searchItem.title }}</h3>
        <p class="searchItem__text">
          {{ searchItem.category }}
        </p>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    searchItem: {
      type: Object,
      required: true,
      default: () => ({
        category: '常設展',
        link: '',
        title: '黃金館',
        type: 'normal',
        web: 'https://goldmuseum.omniguider.com/exhibit/normal'
      })
    }
  }
}
</script>

<style lang="scss">
.searchItem {
  padding: 2.2rem 0;
  display: flex;
  border-bottom: 1px solid #d0a805;

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 2.2rem;
    line-height: 3.2rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
  }

  &__text {
    font-size: 1.6rem;
    line-height: 2.317rem;
    // margin-top: auto;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;

    overflow: hidden;
  }
}
</style>
